.nav_bar {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--purpleDark);
}

.nav_bar_home {
  padding-left: 1rem;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 300;
}
