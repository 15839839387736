.header {
  width: 100%;
  background-color: var(--white);
}

.header_text_wrapper {
  color: var(--purpleDark);
  text-align: center;
}

.header_title {
  margin: 0.75rem 0;
  font-weight: 600;
  background: linear-gradient(
    140deg,
    hsl(245deg 17% 29%) 0%,
    hsl(255deg 26% 35%) 11%,
    hsl(268deg 36% 42%) 22%,
    hsl(279deg 45% 48%) 33%,
    hsl(291deg 54% 55%) 44%,
    hsl(303deg 63% 62%) 56%,
    hsl(314deg 72% 68%) 67%,
    hsl(326deg 82% 75%) 78%,
    hsl(338deg 92% 81%) 89%,
    hsl(350deg 100% 88%) 100%
  );
  background-clip: text;
  color: transparent;
  background-size: 500%;
  animation: animatedGradient 15s infinite linear;
}

@keyframes animatedGradient {
  0% {
    background-position: 0%;
  }
  25% {
    background-position: 25%;
  }
  50% {
    background-position: 50%;
  }
  75% {
    background-position: 75%;
  }
  100% {
    background-position: 0%;
  }
}

.header_sub_title {
  margin: 0.5rem 0;
  font-weight: 500;
}

.header_image {
  width: 100%;
  height: 300px;
  padding: 0;
  background-image: url('../../assets/programmer-small.svg');
  background-repeat: no-repeat;
  background-position: center;
}
