.footer {
  margin-top: -1rem;
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--purpleDark);
  color: var(--gray);
}

a {
  color: var(--gray);
  text-decoration: underline;
}
