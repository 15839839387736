.section__dark {
  padding: 0.5rem 0.5rem 0.5rem 0;
  background-color: var(--purpleDark);
  color: var(--gray);
}

.section__light {
  background-color: var(--white);
  color: var(--purpleDark);
}

.section_title {
  text-align: center;
  font-size: 1.2rem;
}
