.info_container {
  padding: 0 0.5rem;
}
.info_icon_container {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.info_linkedIn {
  width: 150px;
  height: 150px;
  padding: 0;
  background-image: url('../../assets/iconmonstr-linkedin-5.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.info_gitHub {
  width: 150px;
  height: 150px;
  padding: 0;
  background-image: url('../../assets/iconmonstr-github-5.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.info_twitter {
  width: 150px;
  height: 150px;
  padding: 0;
  background-image: url('../../assets/iconmonstr-flickr-5.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.info_text {
  text-align: center;
  line-height: 1.7;
  color: var(--gray);
}
