.card {
  padding: 0.5rem;
  margin: 1rem 0.5rem;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 300px);
  align-self: flex-start;
  min-height: 300px;

  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 0.25rem;
  box-shadow: 0.25rem 0.5rem 0.5rem var(--gray);
}

.card_title {
  color: var(--purpleLight);
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

.card_text {
  color: var(--purpleMedium);
  font-weight: 300;
  line-height: 1.7;
}

.card_job {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.card_period {
  margin: 0;
}
