:root {
  --purpleDark: #2f2e41;
  --purpleMedium: #3f3d56;
  --purpleLight: #6c63ff;
  --white: #ffffff;
  --gray: #e6e6e6;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  font-family: 'Raleway', sans-serif;
}
