.background_container {
  padding: 0 0.5rem;
  max-width: 900px;
  margin: 0 auto;
}

.background_para_wrapper {
  display: flex;
  flex-direction: column;
}

.background_paragraph {
  line-height: 1.7;
}

.paragraph_icon_life {
  background-image: url('../../assets/world-small.svg');
}

.paragraph_icon_career {
  background-image: url('../../assets/resume-small.svg');
}

.paragraph_icon_hobbies {
  background-image: url('../../assets/photographer-small.svg');
}

.icon {
  width: 150px;
  height: 100px;
  margin: 0 auto;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 768px) {
  .background_para_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
    margin-bottom: 4rem;
  }

  .background_paragraph {
    flex: 2;
  }

  .icon {
    flex: 1;
  }
}
