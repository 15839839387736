.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--purpleDark);
  color: var(--gray);
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9rem;
}

.modal_item {
  padding: 3rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
}

.modal_close {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
